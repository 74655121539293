import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private search = new BehaviorSubject<string | null>(null);
  searchChanged$ = this.search.asObservable();

  updateSearch(val: string | null) {
    this.search.next(val);
  }
}
