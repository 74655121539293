<section class="section section-slider">
  <div class="wide-slider">
    <ul class="wide-slider__list">
      <li class="wide-slider__item active" *ngFor="let slide of slides">
        <img class="wide-slider__bg" [src]="slide.images[0] || ''" alt="" />
        <div class="wide-slider__content">
          <div class="wide-slider__tags font__xs">
            <span *ngFor="let cat of slide.categories">{{ cat.title }}</span>
          </div>

          <div class="wide-slider__title h3">
            {{ slide.title }}
          </div>

          <p class="wide-slider__p font__xxs">
            {{ slide.subtitle }}
          </p>

          <p class="wide-slider__p-2 font__xs">
            {{ slide.description }}
          </p>

          <div class="wide-slider__controls">
            <a [routerLink]="['/dapp', slide.id]" class="wide-slider__button button button__filled">
              <span>Try Now</span>
              <span><i class="icon-arrow-right"></i></span>
            </a>

            <span *ngFor="let platform of slide.platforms">
              <i [ngClass]="platform.icon"></i>
            </span>
          </div>
        </div>

        <button class="wide-slider__like">
          <i class="icon-heart"></i>
        </button>
      </li>
    </ul>

    <ul class="wide-slider__nav">
      <li class="active" *ngFor="let slide of slides">
        <img [src]="slide.thumb" alt="" />
      </li>
    </ul>
  </div>
</section>

<section class="section section-baners">
  <ul class="baners">
    <li class="baners__item">
      <picture>
        <source srcset="/assets/images/sl-4.png" media="(min-width: 768px)" />
        <source srcset="/assets/images/sl-7.png" />
        <img src="/assets/images/sl-4.png" alt="" class="baner__image" />
      </picture>
      <a href="#" class="button baners__button button__filled font__s">Learn more</a>
    </li>
    <li class="baners__item">
      <picture>
        <source srcset="/assets/images/sl-5.png" media="(min-width: 768px)" />
        <source srcset="/assets/images/sl-6.png" />
        <img src="/assets/images/sl-6.png" alt="" class="baner__image" />
      </picture>
      <a href="#" class="button baners__button button__filled font__s">Learn more</a>
    </li>
  </ul>
</section>

<app-popular-dapps></app-popular-dapps>

<section class="section section-categories">
  <div class="section__title">
    <h4>Top categories</h4>

    <a [routerLink]="['/explore']" class="all-link">
      View All
      <i class="icon-arrow-right"></i>
    </a>
  </div>
  <ul class="categories">
    <li class="categories__item" *ngFor="let item of topCategories">
      <a [routerLink]="['/explore', item.id]" class="categories__link">
        <img [src]="item.image?.small" [alt]="item.name" />
        <span>{{ item.name }}</span>
      </a>
    </li>
  </ul>
</section>

<section class="section section-games-alt">
  <div class="section__title">
    <h4>Newly listed</h4>

    <a [routerLink]="['/explore']" [queryParams]="{ group: GameGroups.NewlyListed }" class="all-link">
      View All
      <i class="icon-arrow-right"></i>
    </a>
  </div>

  <ul class="games-alt">
    <li class="games-alt__item" *ngFor="let item of newlyListed">
      <app-dapp-mini-block [app]="item"></app-dapp-mini-block>
    </li>
  </ul>
</section>

<section class="section section-assets">
  <div class="section__title">
    <h4>Most popular assets</h4>

    <a [routerLink]="['/explore']" [queryParams]="{ group: GameGroups.Popular }" class="all-link">
      View All
      <i class="icon-arrow-right"></i>
    </a>
  </div>

  <ul class="assets">
    <li class="assets__item" *ngFor="let item of popularAssets">
      <app-asset-mini [asset]="item"></app-asset-mini>
    </li>
  </ul>
</section>

<section class="section section-baner">
  <div class="baner">
    <a class="baner__link" href="#"> <!-- TODO: connect routerLink -->
      <picture>
        <source srcset="/assets/images/game-to-web3.png" media="(min-width: 768px)">
        <source srcset="/assets/images/game-to-web3-2.png" media="(min-width: 0px)">
        <img src="/assets/images/game-to-web3.png" alt="">
      </picture>
    </a>
  </div>
</section>

<section class="section section-games">
  <div class="w-flex">
    <div class="w-50">
      <div class="section__title">
        <h4>Community choice</h4>

        <a [routerLink]="['/explore']" [queryParams]="{ group: GameGroups.CommunityChoice }" class="all-link">
          View All
          <i class="icon-arrow-right"></i>
        </a>
      </div>
      <ul class="games-alt">
        <li class="games-alt__item" *ngFor="let item of communityChoice">
          <app-dapp-mini-block [app]="item"></app-dapp-mini-block>
        </li>
      </ul>
    </div>
    <div class="w-50">
      <div class="section__title">
        <h4>Top rated games</h4>

        <a [routerLink]="['/explore']" [queryParams]="{ group: GameGroups.TopRated }" class="all-link">
          View All
          <i class="icon-arrow-right"></i>
        </a>
      </div>
      <ul class="games-alt">
        <li class="games-alt__item" *ngFor="let item of topRated">
          <app-dapp-mini-block [app]="item"></app-dapp-mini-block>
        </li>
      </ul>
    </div>
  </div>
</section>
