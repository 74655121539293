<a [routerLink]="['/dapp', app.id]" class="games__image">
  <img class="games__cover" [src]="app.banner.medium || ''" alt="" />

  <div class="games__label">
    <img src="/assets/images/polygon.svg" alt="" />
    <span>Polygon</span>
  </div>

  <div class="games__like">
    <i [ngClass]="{ 'icon-heart': true, 'my-favorite': false }"></i> <!-- TODO no field in API response -->
  </div>
</a>

<a [routerLink]="['/dapp', app.id]" class="games__title">{{ app.title }}</a>

<ul class="games__categories">
  <li *ngFor="let cat of app.categories">
    <!-- <a [routerLink]="['/explore', cat.id]">{{ cat.title }}</a>  -->
    <a [routerLink]="['/explore', cat]">{{ cat }}</a>
  </li>
</ul>

<div class="games__votes">
  <div class="games__votes-count">0 votes</div> <!-- TODO no field in API response -->
  <div class="games__votes-rating">
    <i class="icon-star"></i>
    <span> {{ 0 | fixedNumber : 1 }}</span> <!-- TODO no field in API response -->
  </div>
</div>
