interface CacheRecord<T> {
  setAt: number;
  entity: T;
}

export interface StandardEntity {
  id: string;
}

export class EntityCache<T> {
  private records: Record<string, CacheRecord<T>> = {};
  private maxSize: number;

  constructor(maxSize = 100) {
    this.maxSize = maxSize;
  }

  get(id: string): T {
    return this.records[id]?.entity;
  }

  set(key: string, entity: T) {
    // clear old records if max cache size is used
    if (
      Object.keys(this.records).length >= this.maxSize
      && !this.records[key]
    ) {
      this.clearRecords(1);
    }

    this.records[key] = {
      setAt: (new Date()).valueOf(),
      entity,
    };
  }

  setStandardEntity(entity: StandardEntity) {
    this.set(entity.id, entity as T);
  }

  bulkSet(items: { key: string; entity: T }[]) {
    const toUpdate = items.filter((item) => !!this.records[item.key]);
    const toAdd = items.filter((item) => !this.records[item.key]);

    toUpdate.forEach((item) => {
      this.records[item.key] = {
        setAt: (new Date()).valueOf(),
        entity: item.entity,
      };
    });

    // clear old records if > than max cache size may be used
    const countToClear = Object.keys(this.records).length + toAdd.length - this.maxSize;

    this.clearRecords(countToClear);

    toAdd.forEach((item) => {
      this.records[item.key] = {
        setAt: (new Date()).valueOf(),
        entity: item.entity,
      };
    });
  }

  bulkSetStandardEntities(items: StandardEntity[]) {
    const data = items.map((entity) => ({
      key: entity.id,
      entity: entity as T,
    }));

    this.bulkSet(data);
  }

  protected clearRecords(count: number) {
    if (count <= 0) return;

    // collect keys and sort DESC
    const data = Object.keys(this.records)
      .map((key) => ({
        setAt: this.records[key].setAt,
        key,
      }))
      .sort((a, b) => {
        if (a.setAt > b.setAt) return -1;
        if (a.setAt < b.setAt) return 1;

        return 0;
      });

    // delete oldest records
    data.slice(0, count).forEach((item) => {
      delete this.records[item.key];
    });
  }
}
