import { Component, Input } from '@angular/core';
import { Asset } from '@app/models/asset';

@Component({
  selector: 'app-asset-mini',
  templateUrl: './asset-mini.component.html',
})
export class AssetMiniComponent {
  @Input() asset!: Asset;
}
