import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { DApp, DAppDeveloper, Icon } from '@app/models/api';
import { dAppPlatformsToIcons } from '@app/utils/mappers';

@Component({
  selector: 'app-dapp-mini-block',
  templateUrl: './dapp-mini-block.component.html',
})
export class DappMiniBlockComponent {
  private readonly router = inject(Router);
  private readonly apiService = inject(ApiService);

  vault = false;
  appData!: DApp;
  appPlatforms: Icon[] = [];
  developer: DAppDeveloper | null = null;

  @Input() set app(app: DApp) {
    this.appData = app;
    this.appPlatforms = dAppPlatformsToIcons(app.platforms);

    this.apiService.getDeveloper(app.developerId).subscribe((data) => {
      this.developer = data;
    });
  }

  @Input() set inVault(val: boolean) {
    this.vault = !!val;
  }

  @Output() removeFromVault = new EventEmitter<void>();

  toGame(app: DApp, vaultOnly = false) {
    if (!this.vault || (this.vault && vaultOnly)) {
      this.router.navigate(['/dapp', app.id]);
    }
  }
}
