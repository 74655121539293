<div class="modal">
  <div class="modal__reviews" *ngIf="data?.review">
    <div class="modal__reviews-top">
      <div class="modal__reviews-flex">
        <div class="modal__reviews-left">
          <img [src]="data.review.user.avatar" alt="">
        </div>

        <div class="modal__reviews-right">
          <div class="modal__reviews-date">{{ data.review.date | date }}</div>
          <div class="modal__reviews-name">{{ data.review.user.name }}</div>
          <div class="modal__reviews-stars">
            <i class="icon-star"
              *ngFor="let foo of [].constructor(5); let idx = index;"
              [ngClass]="{ marked: (idx + 1) <= data.review.rating }"
            ></i>
          </div>
        </div>
      </div>

      <div class="modal__close">
        <i class="icon-close" (click)="dialogRef.close()"></i>
      </div>
    </div>

    <div class="modal__reviews-full">
      {{ data.review.content }}
    </div>
  </div>
</div>
