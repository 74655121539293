<div class="wrapper">
  <div class="container">
    <div class="wrapper__flex">
      <aside class="sidebar">
        <div class="logo">
          <img src="/assets/images/logo.svg" alt="ReadyGG" />
        </div>

        <div class="user-sidebar" *ngIf="isProfilePage">
          <div class="user-sidebar__avatar">
            <img [src]="user?.profilePicture?.source" alt="Username" />
          </div>

          <div class="user-sidebar__label">Halo rank</div>

          <div class="user-sidebar__name">
            {{ user?.displayName }}
          </div>

          <div class="user-sidebar__email">
            {{ user ? user["email"] : "" }}
          </div>
        </div>

        <nav class="navigation">
          <ul class="navigation__list">
            <li class="navigation__item" *ngFor="let item of menu">
              <a
                class="navigation__link"
                routerLinkActive="active"
                [routerLink]="item.link"
                [routerLinkActiveOptions]="{ exact: true }"
                [ngClass]="{ disabled: item.disabled }"
              >
                <i [ngClass]="item.icon"></i>
                <span>{{ item.title }}</span>
              </a>
            </li>
          </ul>
        </nav>

        <button class="user-logout" *ngIf="isProfilePage" (click)="logout()">
          <span>Log out</span>
          <i class="icon-logout-alt"></i>
        </button>

        <div class="test-baner" *ngIf="!isProfilePage">
          <div class="test-baner__title h4">Test To Earn</div>
          <p class="test-baner__subtitle font__s">
            Test games, receive rewards
          </p>
          <a
            class="test-baner__button button button__filled button__s"
            href="#"
          >
            Test Games
          </a>
          <div class="test-baner__images">
            <img src="/assets/images/tb-2.png" alt="" />
            <img src="/assets/images/tb-1.png" alt="" />
          </div>
        </div>
      </aside>

      <div class="content">
        <header class="section header">
          <div class="mobile-logo">
            <img src="/assets/images/logo.svg" alt="" />
          </div>

          <div class="search">
            <i class="icon-search search__icon"></i>
            <div class="input-wrapper">
              <input
                id="search"
                type="search"
                placeholder="Search games"
                [formControl]="searchCtrl"
              />
            </div>
          </div>

          <div class="notifications">
            <ng-container *ngIf="user">
              <div class="notifications__button">
                <i class="icon-bell"></i>
              </div>

              <div class="notifications__count">9</div>
            </ng-container>
          </div>

          <span
            class="button log-in__button button__bordered"
            *ngIf="!user"
            (click)="openAuthPopup()"
          >
            <i class="icon-person"></i>
            <span class="font__s">Log In</span>
          </span>

          <a class="user-icon" routerLink="/profile" *ngIf="user">
            <img
              [src]="user.profilePicture?.source || '/images/profile.png'"
              alt="My Profile"
            />
          </a>
        </header>

        <main class="main">
          <router-outlet></router-outlet>
        </main>
      </div>

      <aside class="mobile-sidebar">
        <div class="container">
          <ul class="mobile-sidebar__nav mobile-nav">
            <!-- // TODO: hide home & explore for now -->
            <!-- <li class="mobile-nav__item">
              <a
                class="mobile-nav__link"
                [routerLink]="['/explore']"
                routerLinkActive="active"
              >
                <i class="icon-explore-1"></i>
                <span>Explore</span>
              </a>
            </li> -->
            <!-- <li class="mobile-nav__item mobile-nav__home">
              <a
                class="mobile-nav__link _active"
                [routerLink]="['/']"
                routerLinkActive="active"
              >
                <div class="home-svg-wrapper">
                  <i class="icon-home"></i>
                </div>
                <span>Home</span>
              </a>
            </li> -->
            <li class="mobile-nav__item">
              <a
                class="mobile-nav__link"
                [routerLink]="['/marketplace']"
                routerLinkActive="active"
              >
                <i class="icon-cart"></i>
                <span>Marketplace</span>
              </a>
            </li>

            <li class="mobile-nav__item">
              <a
                class="mobile-nav__link"
                [routerLink]="['/inventory']"
                routerLinkActive="active"
              >
                <i class="icon-cart"></i>
                <span>Inventory</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  </div>
  <img class="wrapper__bg-1" src="/assets/images/bg-0.svg" alt="" />
  <img class="wrapper__bg-2" src="/assets/images/bg-1.svg" alt="" />
</div>
