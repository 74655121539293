import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlDomain',
})
export class UrlDomainPipe implements PipeTransform {
  transform(value: string): string {
    try {
      const url = new URL(value);
      return url.hostname;
    } catch (err) {
      return value;
    }
  }
}
