<div [ngClass]="{'games-alt__wrapper': true, 'games-alt__link': !vault }" (click)="toGame(appData)">
  <div class="games-alt__image"  (click)="toGame(appData, true)">
    <img [src]="appData.icon.medium || ''" alt="" />
    <div class="games-alt__label">
      <i class="icon-infinity"></i>
    </div>
  </div>
  <div class="games-alt__content">
    <div class="games-alt__title" (click)="toGame(appData, true)">{{ appData.title }}</div>
    <div class="games-alt__vendor">{{ developer?.name }}</div>
    <div class="games-alt__footer">
      <ng-container *ngIf="!vault; else inVault">
        <div class="games-alt__rating">
          <i class="icon-star"></i>
          <span>{{ 0 | fixedNumber : 1 }}</span> <!-- TODO no field in API response -->
        </div>

        <div class="games-alt__div">
          <i class="icon-dot"></i>
        </div>

        <div class="games-alt__devices">
          <i
            *ngFor="let platform of appPlatforms"
            [ngClass]="platform.iconClass"
            [title]="platform.name"
          ></i>
        </div>
      </ng-container>

      <ng-template #inVault>
        <span class="games-alt__remove game-remove-link" (click)="removeFromVault.emit()">
          Remove from vault
        </span>
      </ng-template>
    </div>
  </div>
</div>
