/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';

import { CustomSelectOption, Sort, View } from '@app/constants/all';
import { DApp } from '@app/models/api';
import { ApiService } from '@app/core/services/api.service';
import { WalletPopupComponent } from '@app/shared/components/wallet-popup/wallet-popup.component';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
})
export class VaultComponent implements OnInit {
  private readonly apiService = inject(ApiService);
  readonly dialog = inject(Dialog);

  View = View;

  myApps: DApp[] = [];
  view = View.Icons;

  sortCtrl = new FormControl<Sort>(Sort.RecentlyAdded);
  sortOptions: CustomSelectOption[] = [
    {
      title: 'Recently added',
      value: Sort.RecentlyAdded,
    },
    {
      title: 'Rating',
      value: Sort.Rating,
    },
    {
      title: 'Release Date',
      value: Sort.ReleaseDate,
    },
    {
      title: 'Popularity',
      value: Sort.Popularity,
    },
    {
      title: 'Relevance',
      value: Sort.Relevance,
    },
  ];

  ngOnInit(): void {
    // TODO: apply sort (& pagination?)
    this.apiService.getMyApps().subscribe((data) => {
      this.myApps = [];
    });

    this.dialog.open(WalletPopupComponent); // DEBUG stuff
  }

  removeFromVault(game: DApp) {
    // TODO:
    this.apiService.removeFromVault(game.id).subscribe((data) => {
      // TODO: ???
    });
  }
}
