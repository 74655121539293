import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomSelectOption } from '@app/constants/all';

type OnChangeHandler = (val: unknown) => void;

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomSelectComponent,
      multi: true,
    },
  ],
})
export class CustomSelectComponent implements ControlValueAccessor {
  selectedOption: CustomSelectOption | null = null;

  onChange!: OnChangeHandler;

  @Input() options: CustomSelectOption[] = [];
  @Input() placeholder = '';
  @Input() label = '';

  @Output() changed = new EventEmitter<unknown>();

  writeValue(value: string) {
    const option = this.options.find((o) => o.value === value);
    this.selectedOption = option || null;
  }

  registerOnChange(fn: OnChangeHandler) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  registerOnTouched(fn: OnChangeHandler) {}

  selected(option: CustomSelectOption) {
    this.selectedOption = option;
    if (this.onChange) this.onChange(option.value);
    this.changed.emit(option.value);
  }
}
