import { Subject } from 'rxjs';

export enum FbPageCursor {
  Prev = 'prev',
  Next = 'next',
}

export class BaseFbPagination {
  isNextPage = false;
  isPrevPage = false;
  pageCursor = new Subject<FbPageCursor>();

  prevPage() {
    if (this.isPrevPage) {
      this.pageCursor.next(FbPageCursor.Prev);
    }
  }

  nextPage() {
    if (this.isNextPage) {
      this.pageCursor.next(FbPageCursor.Next);
    }
  }
}
