/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { Dialog } from '@angular/cdk/dialog';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, filter } from 'rxjs';
import { TEXT_FIELD_DEBOUNCE_TIME } from './constants/all';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { IAuthNewUserData } from './models/auth.interface';

interface MenuItem {
  title: string;
  icon: string;
  link: string | string[];
  disabled: boolean;
  forLogged?: boolean;
}

const allMenu: MenuItem[] = [
  // TODO: hide home & explore for now
  // {
  //   title: 'Home',
  //   icon: 'icon-home',
  //   link: '/',
  //   disabled: false,
  // },
  // {
  //   title: 'Explore',
  //   icon: 'icon-explore',
  //   link: '/explore',
  //   disabled: false,
  // },
  {
    title: 'Marketplace',
    icon: 'icon-cart',
    link: '/marketplace',
    disabled: false,
  },
  {
    title: 'Inventory',
    icon: 'icon-inventory',
    link: '/inventory',
    disabled: false,
  },
];

const profileMenu: MenuItem[] = [
  {
    title: 'Profile Overview',
    icon: 'icon-person',
    link: '/profile/overview',
    disabled: false,
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  readonly commonService = inject(CommonService);
  readonly dialog = inject(Dialog);

  private updateMenu = new Subject<void>();

  title = 'dapp';
  menu: MenuItem[] = [];
  user: IAuthNewUserData | null = null;
  searchCtrl = new FormControl<string>('');
  isProfilePage = false;

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.user = user;
      this.updateMenu.next();
    });

    this.searchCtrl.valueChanges
      .pipe(debounceTime(TEXT_FIELD_DEBOUNCE_TIME))
      .subscribe((search) => {
        this.commonService.updateSearch(search);
        this.searchCtrl.setValue(null, { emitEvent: false });
        this.router.navigate(['/explore']);
      });

    // TODO: listen router to detect profile page
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const ev = event as NavigationEnd;
        // console.log('nav event', event);
        this.isProfilePage = ev.urlAfterRedirects.startsWith('/profile');

        this.updateMenu.next();
      });

    this.updateMenu.subscribe(() => {
      if (this.isProfilePage) {
        this.menu = [...profileMenu];
      } else {
        this.menu = allMenu.filter((item) => {
          let allow = true;

          if (item.forLogged) {
            allow &&= !!this.user;
          }

          return allow;
        });
      }
    });

    this.updateMenu.next();
  }

  openAuthPopup() {
    this.authService.runOAuth('signin', true);
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['/']);
  }
}
