<div class="modal">
  <div class="modal__reviews-add">
    <div class="modal__reviews-add__top">
      <div class="modal__reviews-add__flex">
        <div class="modal__reviews-add__image">
          <img [src]="data.app.icon.small" alt="" />
        </div>

        <div class="modal__reviews-add__content">
          <div class="modal__reviews-add__title">{{ data.app.title }}</div>
          <div class="modal__reviews-add__label">All reviews</div>
        </div>
      </div>
      <div class="modal__close">
        <i class="icon-close" (click)="dialogRef.close()"></i>
      </div>
    </div>

    <form
      class="modal__reviews-add__edit"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="modal__reviews-add__stars">
        <i
          class="icon-star"
          *ngFor="let foo of [].constructor(5); let idx = index"
          [ngClass]="{ marked: isMarked(idx) }"
          (click)="form.get('rating')?.patchValue(idx + 1)"
        ></i>
      </div>

      <div class="modal__reviews-add__container">
        <div class="modal__reviews-add__flex">
          <div class="modal__reviews-add__image-2">
            <img [src]="user?.profilePicture?.source" alt="" />
          </div>
          <div class="modal__reviews-add__content">
            <div class="modal__reviews-add__title">{{ user?.displayName }}</div>
          </div>
        </div>

        <textarea
          formControlName="review"
          placeholder="How did you feel when you played? Would you recommend this game to other users?"
        ></textarea>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</div>
