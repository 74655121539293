import { Component, OnInit, inject } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { BehaviorSubject, debounceTime, merge, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CustomSelectOption,
  GamesFilter,
  Sort,
  TEXT_FIELD_DEBOUNCE_TIME,
  View,
} from '@app/constants/all';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { FormControl } from '@angular/forms';
import { BaseFbPagination, FbPageCursor } from '@app/models/base-fb-pagination';
import {
  DApp,
  DAppCategory,
  DAppQueryParams,
  DAppQueryTypes,
} from '@app/models/api';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
})
export class ExploreComponent extends BaseFbPagination implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly apiService = inject(ApiService);
  commonService = inject(CommonService);

  View = View;
  categories: DAppCategory[] = [];
  apps: DApp[] = [];

  // lastGameId
  // totalItems = 0;
  view = View.Icons;

  searchCtrl = new FormControl<string>('');

  filter = new BehaviorSubject<GamesFilter>({
    categoryId: null,
    group: null,
    search: null,
  });

  chainCtrl = new FormControl<string>('');
  chainOptions: CustomSelectOption[] = [
    // TODO: what is chain here?
  ];

  platformCtrl = new FormControl<string>('');
  platformOptions: CustomSelectOption[] = [
    {
      title: 'Android',
      value: 'android',
    },
    {
      title: 'iOS',
      value: 'ios',
    },
    {
      title: 'Windows',
      value: 'win',
    },
  ];

  sortCtrl = new FormControl<Sort>(Sort.RecentlyAdded);
  sortOptions: CustomSelectOption[] = [
    {
      title: 'Recently added',
      value: Sort.RecentlyAdded,
    },
    {
      title: 'Rating',
      value: Sort.Rating,
    },
    {
      title: 'Release Date',
      value: Sort.ReleaseDate,
    },
    {
      title: 'Popularity',
      value: Sort.Popularity,
    },
    {
      title: 'Relevance',
      value: Sort.Relevance,
    },
  ];

  constructor() {
    super();

    merge(
      // this.sortCtrl.valueChanges, not implemented in current api
      this.pageCursor,
      this.filter.pipe(
        tap(() => {
          this.apps = [];
          this.isNextPage = false;
          this.isPrevPage = false;
        }),
      ),
    )
      .pipe(
        takeUntilDestroyed(),
        debounceTime(20),
        switchMap((event) => {
          /*
            this.filter.value,
            this.page.value, 9,
            this.sortCtrl.value as Sort, // no sort feature in API
          */

          const params: DAppQueryParams = {
            queryType: DAppQueryTypes.Search,
            // query: '',
            limit: 9,
          };

          if (this.filter.value.search) {
            params.query = this.filter.value.search;
            params.queryType = DAppQueryTypes.Search;
          }

          if (event === FbPageCursor.Next && this.apps.length > 0) {
            params.startAfter = this.apps[this.apps.length - 1].id;
          }

          if (event === FbPageCursor.Prev && this.apps.length > 0) {
            params.endBefore = this.apps[0].id;
          }

          return this.apiService.getApps(params);
        }),
      )
      .subscribe((data) => {
        this.apps = data.items;
        this.isPrevPage = data.prevItemsPresent;
        this.isNextPage = data.nextItemsPresent;
      });

    this.route.params.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (typeof params['categoryId'] !== 'undefined') {
        this.toCategory(params['categoryId'] || null);
      }
    });

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((qp) => {
      if (qp['gameGroup']) {
        const newFilter: GamesFilter = { ...this.filter.value };
        newFilter.group = qp['gameGroup'];
        this.filter.next(newFilter);
      }
    });

    this.commonService.searchChanged$
      .pipe(takeUntilDestroyed())
      .subscribe((val) => this.searchCtrl.setValue(val));

    this.searchCtrl.valueChanges
      .pipe(takeUntilDestroyed(), debounceTime(TEXT_FIELD_DEBOUNCE_TIME))
      .subscribe((search) => {
        const newFilter: GamesFilter = { ...this.filter.value };
        newFilter.search = search;
        this.filter.next(newFilter);
      });
  }

  ngOnInit(): void {
    this.apiService.getCategories().subscribe((data) => {
      this.categories = data;
    });
  }

  toCategory(id: string | null) {
    const newFilter: GamesFilter = { ...this.filter.value };
    newFilter.categoryId = id;
    this.filter.next(newFilter);
  }
}
