import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeHandler = (val: unknown) => void;

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomCheckboxComponent,
      multi: true,
    },
  ],
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  state = false;
  onChange!: OnChangeHandler;

  @Input() label: string | null = null;
  @Output() changed = new EventEmitter<boolean>();

  writeValue(value: boolean) {
    this.state = value;
  }

  registerOnChange(fn: OnChangeHandler) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  registerOnTouched(fn: OnChangeHandler) {}

  toggle() {
    this.state = !this.state;
    if (this.onChange) this.onChange(this.state);
    this.changed.emit(this.state);
  }
}
