import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [ButtonModule, ConfirmDialogModule],
  exports: [ButtonModule, ConfirmDialogModule],
  declarations: [],
  providers: [],
})
export class PrimeNgModule {}
