import { Component, Inject, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { GameReview } from '@app/models/game';

@Component({
  selector: 'app-review-view-popup',
  templateUrl: './review-view-popup.component.html',
})
export class ReviewViewPopupComponent {
  readonly dialogRef = inject(DialogRef<string>);

  constructor(@Inject(DIALOG_DATA) public data: { review: GameReview }) {}
}
