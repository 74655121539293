import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedNumber',
})
export class FixedNumberPipe implements PipeTransform {

  transform(value: number, precision = 0): string {
    if (typeof(value) === 'number') {
      return value.toFixed(precision);
    }

    return value;
  }

}
