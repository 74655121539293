<div class="modal">
  <div class="modal__wallet">
    <div class="modal__close" (click)="dialogRef.close()">
      <i class="icon-close"></i>
    </div>

    <div class="modal__wallet-title">
      Connect to your wallet
    </div>

    <ul class="wallets">
      <li class="wallets__item" *ngFor="let wallet of wallets" (click)="connectWallet(wallet.address)">
        <!-- icon here?  -->
        <span>{{ wallet.address }}</span>
        <i class="icon-chevron-down next-icon"></i>
      </li>
    </ul>
  </div>
</div>
