<a
  class="assets__link"
  [routerLink]="['/marketplace/asset', asset.id]"
  [ngClass]="{ sold: asset.sold }"
>
  <div class="assets__image">
    <img *ngIf="!asset.image" src="/assets/images/asset-default.png" alt="" />
    <img *ngIf="asset.image" [src]="asset.image.large" alt="" />
    <span class="assets__tag" *ngIf="asset.new">🔥 New</span>
  </div>
  <div class="assets__content">
    <div class="assets__use">{{ asset.title }}</div>
    <div class="assets__title">
      {{ asset.title }}
    </div>
    <div class="assets__price">
      <span>Floor</span>
      <div>{{ asset.price }} $RDYX</div>
    </div>
  </div>
</a>
