<ng-container *ngIf="myApps.length > 0; else empty">
  <section class="section section-baner section-baner-vault">
    <div class="baner">
      <picture>
        <source srcset="/assets/images/my-games.png" media="(min-width: 768px)">
        <source srcset="/assets/images/my-games-mobile.png" media="(min-width: 0px)">
        <img src="/assets/images/my-games.png" alt="">
      </picture>
    </div>
  </section>

  <section class="section section-games-list">
    <div class="games-list">
      <div class="games-list__nav">
        <div class="games-list__nav-item">
          <app-custom-select label="Sort by:"
            [options]="sortOptions"
            [formControl]="sortCtrl"
          ></app-custom-select>
        </div>
        <div class="games-list__nav-toggle">
          <span class="games-list__nav-toggle-link"
            [ngClass]="{ active: this.view === View.Icons }"
            (click)="this.view = View.Icons"
            >
            <i class="icon-view-icons"></i>
          </span>
          <span class="games-list__nav-toggle-link"
            [ngClass]="{ active: this.view === View.List }"
            (click)="this.view = View.List"
            >
            <i class="icon-view-list"></i>
          </span>
        </div>
      </div>

      <ul class="games-alt">
        <li class="games-alt__item" *ngFor="let item of myApps">
          <app-dapp-mini-block
            [app]="item"
            [inVault]="true"
            (removeFromVault)="removeFromVault(item)"
          ></app-dapp-mini-block>
        </li>
      </ul>
    </div>
  </section>
</ng-container>

<ng-template #empty>
  <section class="section section-vault-empty">
    <div class="vault-empty">
      <div class="vault-empty__left">
        <img src="/assets/images/vault-empty.gif" alt="">
      </div>
      <div class="vault-empty__right">
        <h1 class="vault-empty__title h3">Your vault is empty</h1>
        <p class="vault-empty__subtitle">Move to store and add some games!</p>
        <a routerLink="/explore" class="button button__filled vault-empty__btn">
          <span>Explore store</span>
          <i class="icon-arrow-right"></i>
        </a>
      </div>
    </div>
  </section>

  <app-popular-dapps></app-popular-dapps>
</ng-template>
