<section class="section section-baner section-baner-explore">
  <div class="baner">
    <picture>
      <source srcset="/assets/images/explore-baner.png" media="(min-width: 768px)">
      <source srcset="/assets/images/explore-baner-mobile.png" media="(min-width: 0px)">
      <img src="/assets/images/explore-baner.png" alt="">
    </picture>
  </div>
</section>

<section class="section explore-categories">
  <ul class="categories">
    <li class="categories__item">
      <a [routerLink]="['/explore']" class="categories__link category-all"
        [ngClass]="{ active: filter.value.categoryId === null }"
        >
        All Categories
      </a>
    </li>
    <li class="categories__item" *ngFor="let cat of categories">
      <a [routerLink]="['/explore', cat.id]" class="categories__link"
        [ngClass]="{ active: filter.value.categoryId === cat.id }"
        >
        <img [src]="cat.image?.small" [alt]="cat.name" /> <!-- TODO: fix props -->
        <span>{{ cat.name }}</span>
      </a>
    </li>
  </ul>
</section>

<section class="section section-games-list">
  <div class="games-list">
    <div class="games-list__nav games-list__nav-filters">
      <!--
      <div class="games-list__nav-item chains">
        <app-custom-select placeholder="All chains"
          [options]="chainOptions"
          [formControl]="chainCtrl"
        ></app-custom-select>
      </div>
      -->
      <div class="games-list__nav-item platform">
        <app-custom-select placeholder="Platform"
          [options]="platformOptions"
          [formControl]="platformCtrl"
        ></app-custom-select>
      </div>
      <div class="games-list__nav-item sortby">
        <app-custom-select label="Sort by:"
          [options]="sortOptions"
          [formControl]="sortCtrl"
        ></app-custom-select>
      </div>
    </div>

    <div class="games-list__nav  games-list__nav-search">
      <div class="games-list__nav-search-container">
        <i class="icon-search"></i>
        <input type="search" [formControl]="searchCtrl">
      </div>
      <div class="games-list__nav-search-results" *ngIf="searchCtrl.value">
        Results for
        <span>
          {{ searchCtrl.value }}
          <i class="icon-close remove-from-search" (click)="searchCtrl.patchValue('')"></i>
        </span>
      </div>
    </div>

    <div class="games-list__nav games-list__nav-views">
      <div class="games-list__nav-item">
        <!-- no total matched count in API response
        <div class="games-list__nav-title h6">
          Showing 50 games
        </div>
        -->
      </div>
      <div class="games-list__nav-toggle">
        <span class="games-list__nav-toggle-link"
          [ngClass]="{ active: this.view === View.Icons }"
          (click)="this.view = View.Icons"
          >
          <i class="icon-view-icons"></i>
        </span>
        <span class="games-list__nav-toggle-link"
          [ngClass]="{ active: this.view === View.List }"
          (click)="this.view = View.List"
          >
          <i class="icon-view-list"></i>
        </span>
      </div>
    </div>

    <ul class="games">
      <li class="games__item" *ngFor="let game of apps">
        <app-dapp-mid-block [app]="game"></app-dapp-mid-block>
      </li>
    </ul>
  </div>
</section>

<section class="section section-pagination">
  <ul class="pagination">
    <li class="pagination__prev"
      [ngClass]="{ disabled: !isPrevPage }"
      (click)="prevPage()"
      >
      <i class="icon-chevron-down"></i>
      <span>Previous</span>
    </li>

    <li class="pagination__next"
      [ngClass]="{ disabled: !isNextPage }"
      (click)="nextPage()"
      >
      <span>Next</span>
      <i class="icon-chevron-down"></i>
    </li>
  </ul>
</section>

<section class="section section-baner">
  <div class="baner">
    <a class="baner__link" href="#">
      <picture>
        <source srcset="/assets/images/game-to-web3.png" media="(min-width: 768px)">
        <source srcset="/assets/images/game-to-web3-2.png" media="(min-width: 0px)">
        <img src="/assets/images/game-to-web3.png" alt="">
      </picture>
    </a>
  </div>
</section>
