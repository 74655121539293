import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Wallet } from '@app/models/api';

@Component({
  selector: 'app-wallet-popup',
  templateUrl: './wallet-popup.component.html',
})
export class WalletPopupComponent {
  private readonly authService = inject(AuthService);
  readonly dialogRef = inject(DialogRef<string>);

  wallets: Wallet[] = [];

  // ngOnInit(): void {
  //   // this.authService.getUserWallets().subscribe((data) => {
  //   //   this.wallets = data;
  //   // });
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connectWallet(addr: string) {
    this.dialogRef.close();
  }
}
