import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface InfoPopupParams {
  title: string;
  text?: string;
  imageUrl?: string;
  buttonText: string;
}

@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
})
export class InfoPopupComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: InfoPopupParams,
    public dialogRef: DialogRef<string>,
  ) {}

}
