<div class="modal">
  <div class="modal__info">
    <div class="modal__close">
      <i class="icon-close" (click)="dialogRef.close()"></i>
    </div>

    <div class="modal__info-image" *ngIf="data.imageUrl">
      <img [src]="data.imageUrl" alt="" />
    </div>

    <div class="modal__info-title">{{ data.title }}</div>

    <div class="modal__info-text" *ngIf="data.text">{{ data.text }}</div>

    <div class="form__item form__item-submit">
      <button type="submit" (click)="dialogRef.close()">
        {{ data.buttonText }}
      </button>
    </div>
  </div>
</div>
