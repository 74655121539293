import { Component, OnInit, inject } from '@angular/core';
import { GameGroups } from '@app/constants/all';
import { ApiService } from '@app/core/services/api.service';
import { DApp, DAppQueryTypes } from '@app/models/api';

@Component({
  selector: 'app-popular-dapps',
  templateUrl: './popular-dapps.component.html',
})
export class PopularDappsComponent implements OnInit {
  private readonly apiService = inject(ApiService);
  GameGroups = GameGroups;

  popular: DApp[] = [];

  ngOnInit(): void {
    const limit = 3;

    this.apiService
      .getApps({
        queryType: DAppQueryTypes.Popular,
        query: '',
        startAfter: '',
        limit,
      })
      .subscribe((data) => {
        this.popular = data.items.slice(0, limit);
      });
  }
}
