<ul class="pagination">
  <li class="pagination__prev"
    [ngClass]="{ disabled: curPage === 1 }"
    (click)="prevPage()"
    >
    <i class="icon-chevron-down"></i>
    <span>Previous</span>
  </li>

  <li class="num" *ngFor="let page of pages"
    [ngClass]="{ active: page.active }"
    (click)="setCurPage(page.value)"
    >
    <span>{{ page.text }}</span>
  </li>

  <li class="pagination__next"
    [ngClass]="{ disabled: curPage === maxPage }"
    (click)="nextPage()"
    >
    <span>Next</span>
    <i class="icon-chevron-down"></i>
  </li>
</ul>
