import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compactNumber',
})
export class CompactNumberPipe implements PipeTransform {
  formatter = Intl.NumberFormat('en', { notation: 'compact' });

  transform(value: number): string {
    return this.formatter.format(value);
  }
}
