import { Component, OnInit, inject } from '@angular/core';
import { GameGroups } from '@app/constants/all';
import { DApp, DAppCategory, DAppQueryTypes } from '@app/models/api';
import { Asset } from '@app/models/asset';
import { Game } from '@app/models/game';
import { fetchAppsFromBatchResponse } from '@app/utils/mappers';
import { ApiService } from '@app/core/services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  private readonly apiService = inject(ApiService);

  GameGroups = GameGroups;

  slides: Game[] = [];
  newlyListed: DApp[] = [];
  communityChoice: DApp[] = [];
  topRated: DApp[] = [];
  popularAssets: Asset[] = [];
  topCategories: DAppCategory[] = [];

  ngOnInit(): void {
    this.apiService.getSilderApps(4).subscribe((data) => {
      this.slides = data;
    });

    this.apiService.getPopularAssets(6).subscribe((data) => {
      this.popularAssets = data;
    });

    this.apiService.getTopCategories(4).subscribe((data) => {
      this.topCategories = data;
    });

    this.apiService
      .getHomeData([
        {
          queryType: DAppQueryTypes.TopRated,
          limit: 4,
        },
        {
          queryType: DAppQueryTypes.NewlyListed,
          limit: 4,
        },
        {
          queryType: DAppQueryTypes.CommunityChoice,
          limit: 4,
        },
      ])
      .subscribe((data) => {
        this.topRated = fetchAppsFromBatchResponse(
          data,
          DAppQueryTypes.TopRated,
          4,
        );

        this.newlyListed = fetchAppsFromBatchResponse(
          data,
          DAppQueryTypes.NewlyListed,
          4,
        );

        this.communityChoice = fetchAppsFromBatchResponse(
          data,
          DAppQueryTypes.CommunityChoice,
          4,
        );
      });
  }
}
