/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mockReviews, mockGames } from 'src/app/mock-data/mock';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { GameReview } from '@app/models/game';
import { environment } from '@env';
import {
  DApp,
  DAppCategory,
  DAppDeveloper,
  DAppHomePageData,
  DAppQueryParams,
  DAppQueryTypes,
  Pageable,
} from '@app/models/api';
import { EntityCache } from '../entity-cache';
import { Asset, AssetRequestParams } from '@app/models/asset';
import { mockAsset } from '@app/mock-data/mock';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly http = inject(HttpClient);

  private developersCache = new EntityCache<Observable<DAppDeveloper>>(1000);
  private appsCache = new EntityCache<DApp>(1000);
  private categoriesCache = new EntityCache<Observable<DAppCategory[]>>(1);

  getSilderApps(limit: number) {
    return of([
      mockGames[0],
      // mockGames[0],
    ]);
  }

  getAppDetails(id: string) {
    const cached = this.appsCache.get(id);
    if (cached) return of(cached);

    return this.http
      .post<{ dapp: DApp }>(`${environment.apiUrl}/dapp-getDAppById`, { id })
      .pipe(
        map((data) => {
          this.appsCache.setStandardEntity(data.dapp);

          return data.dapp;
        }),
      );
  }

  toggleFavorite(id: string) {
    const game = mockGames.find((g) => g.id === id);

    if (game) {
      game.myFavorite = !game.myFavorite;
    }

    return of(game?.myFavorite || false);
  }

  getMyReviewForApp(gameId: string): Observable<GameReview | null> {
    return of(null); // mockReviews[0]
  }

  getReviewsForApp(gameId: string) {
    return of(mockReviews);
  }

  getAlternativesForApp(id: string, limit: number) {
    // TODO: it's debug implementation
    return this.getApps({
      queryType: DAppQueryTypes.Popular,
      limit,
    }).pipe(map((response) => response.items));
  }

  getMyApps() {
    return of([
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
      mockGames[1],
    ]);
  }

  addToVault(id: string) {
    // TODO: ???
    return of(true);
  }

  removeFromVault(id: string) {
    // TODO: ???
    return of(true);
  }

  saveReview(userId: string, gameId: string, review: Partial<GameReview>) {
    console.log(
      `save review for game id ${gameId}: rating ${review.rating}, comment: ${review.content}`,
    );

    // TODO: save to DB
    return of(true);
  }

  getHomeData(params: DAppQueryParams[]) {
    return this.http
      .post<{ homePageData: DAppHomePageData }>(
      `${environment.apiUrl}/dapp-getHomePageData`,
      { queryArray: params },
    )
      .pipe(
        map((response) => {
          this.appsCache.bulkSetStandardEntities(response.homePageData.dapps);

          return response.homePageData;
        }),
      );
  }

  getApps(params: DAppQueryParams) {
    return this.http
      .post<{ dapps: DApp[] }>(`${environment.apiUrl}/dapp-getDApps`, {
      ...params,
      limit: params.limit + 1,
    })
      .pipe(
        map((response) => {
          this.appsCache.bulkSetStandardEntities(response.dapps);

          const items =
            response.dapps.length === params.limit
              ? response.dapps.slice(0, -1)
              : response.dapps;

          return {
            items,
            nextItemsPresent: response.dapps.length > params.limit,
            prevItemsPresent: !!params.startAfter,
          };
        }),
        catchError((err) => {
          return of({
            items: [],
            nextItemsPresent: false,
            prevItemsPresent: false,
          });
        }),
      );
  }

  getDeveloper(id: string) {
    const cached = this.developersCache.get(id);

    if (cached) {
      return cached;
    }

    const request = this.http
      .post<{ developer: DAppDeveloper }>(
      `${environment.apiUrl}/dapp-getDeveloperById`,
      { id },
    )
      .pipe(
        map((response) => response.developer),
        shareReplay(1),
      );

    this.developersCache.set(id, request);

    return request;
  }

  getTopCategories(limit: number) {
    return this.getCategories().pipe(
      map((categories) => {
        // TODO: sort/filter top categories. Now not clear what is "top" marker (tag ?)
        return categories.slice(0, limit);
      }),
    );
  }

  getCategories() {
    const cacheKey = 'all';
    const cached = this.categoriesCache.get(cacheKey);

    if (cached) {
      return cached;
    }

    const request = this.http
      .post<{ categories: DAppCategory[] }>(
      `${environment.apiUrl}/dapp-getCategories`,
      { filter: 'all' },
    )
      .pipe(
        map((response) => response.categories),
        shareReplay(1),
      );

    this.categoriesCache.set(cacheKey, request);

    return request;
  }

  getAssets(params: AssetRequestParams): Observable<Pageable<Asset>> {
    const newAsset = {
      ...mockAsset,
      new: true,
    };

    const soldAsset = {
      ...mockAsset,
      sold: true,
    };

    return of({
      items: [
        // TODO: add new & discounted
        mockAsset,
        mockAsset,
        mockAsset,
        mockAsset,
        mockAsset,
        newAsset,
        mockAsset,
        mockAsset,
        mockAsset,
        mockAsset,
        soldAsset,
        mockAsset,
      ],
      totalItems: 2310,
    });
  }

  getPopularAssets(limit: number) {
    return of([
      mockAsset,
      mockAsset,
      mockAsset,
      mockAsset,
      mockAsset,
      mockAsset,
    ]);
  }

  getAsset(id: string) {
    return of(mockAsset);
  }

  buyAsset(id: string, count: number) {
    return of(true);
  }
}
