<div class="select">
  <div class="select__current" [cdkMenuTriggerFor]="popup">
    <span>{{ label }} <span [ngClass]="{ 'pink is-chosen': !!selectedOption }">{{ selectedOption?.title || placeholder }}</span></span>
    <i class="icon-chevron-down"></i>
  </div>
</div>

<ng-template #popup>
  <div class="select__list" cdkMenu>
    <ul>
      <li cdkMenuItem
        *ngFor="let item of options"
        [ngClass]="{ active: item.value === selectedOption?.value}"
        (click)="selected(item)"
        >
        {{ item.title }}
      </li>
    </ul>
  </div>
</ng-template>
