import { Component, Input } from '@angular/core';
import { DApp } from '@app/models/api';

@Component({
  selector: 'app-dapp-mid-block',
  templateUrl: './dapp-mid-block.component.html',
})
export class DappMidBlockComponent {
  @Input() app!: DApp;
}
