<section class="section section-games">
  <div class="section__title">
    <h4>Popular games</h4>

    <a [routerLink]="['/explore']" [queryParams]="{ group: GameGroups.Popular }" class="all-link">
      View All
      <i class="icon-arrow-right"></i>
    </a>
  </div>

  <ul class="games">
    <li class="games__item" *ngFor="let item of popular">
      <app-dapp-mid-block [app]="item"></app-dapp-mid-block>
    </li>
  </ul>
</section>
