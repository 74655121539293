import { Asset } from '@app/models/asset';
import { Category, Game, GameReview, Statistics } from '@app/models/game';

export const mockCategories: Category[] = [
  {
    id: 'arcade',
    title: 'Arcade',
    icon: '/assets/images/arcade.png',
    iconAlt: '/assets/images/c1.png',
  },
  {
    id: 'action',
    title: 'Action',
    icon: '/assets/images/action.png',
    iconAlt: '/assets/images/c4.png',
  },
  {
    id: 'rpg',
    title: 'RPG',
    icon: '/assets/images/rpg.png',
    iconAlt: '/assets/images/c2.png',
  },
  {
    id: 'casino',
    title: 'Casino',
    icon: '/assets/images/casino.png',
    iconAlt: '/assets/images/c3.png',
  },
  {
    id: 'board-games',
    title: 'Board Games',
    icon: '/assets/images/board-games.png',
  },
  {
    id: 'casual',
    title: 'Casual',
    icon: '/assets/images/casual.png',
  },
  {
    id: 'racing',
    title: 'Racing',
    icon: '/assets/images/racing.png',
  },
  {
    id: 'sport',
    title: 'Sport',
    icon: '/assets/images/sport.png',
  },
  {
    id: 'strategy',
    title: 'Strategy',
    icon: '/assets/images/strategy.png',
  },
  {
    id: 'cards',
    title: 'Cards',
    icon: '/assets/images/cards.png',
  },
  {
    id: 'adventure',
    title: 'Adventure',
    icon: '/assets/images/adventure.png',
  },
  {
    title: 'Hot',
    id: 'hot',
    icon: '',
  },
  {
    title: '#1 in RPG',
    id: 'rpg-1',
    icon: '',
  },
];

export const mockGames: Game[] = [
  {
    // slider
    id: '1',
    title: 'Runestone Keeper 1',
    images: [
      '/assets/images/slide-1.jpg',
      '/assets/images/gallery/g-1.png',
      '/assets/images/gallery/g-2.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
    ],
    thumb: '/assets/images/sl-0.png',
    categories: mockCategories.slice(11, 13),
    subtitle: 'Roguelike | Turn-Based | Role-Playing | Dungeon Crawler',
    description: `Runestone Keeper is a roguelike, turn based dungeon crawler featuring classic elements from many of your old game collections.

    Grab your leather pack, sharpen them dusty swords, step into the dungeon, and put an end to evil once and for all!`,
    publisher: {
      title: 'Sambrela LLC',
      websiteUrl: 'https://www.runestonekeeper.com',
      email: 'support@runestonekeeper.com',
      privacyPolicyUrl: 'http://www.innoteg.com/',
    },
    rating: 4.1,
    votes: 155,
    myFavorite: false,
    platforms: [
      { icon: 'icon-android' },
      { icon: 'icon-macos' },
      { icon: 'icon-desktop' },
    ],
  },
  {
    // midblock
    id: '2',
    title: 'Crypto Police Dog Online',
    images: [
      '/assets/images/game.png',
      '/assets/images/gallery/g-1.png',
      '/assets/images/gallery/g-2.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
    ],
    thumb: '/assets/images/game-icon.png',
    categories: [mockCategories[0], mockCategories[0], mockCategories[0]],
    subtitle: '',
    description: `Runestone Keeper is a roguelike, turn based dungeon crawler featuring classic elements from many of your old game collections.

    Grab your leather pack, sharpen them dusty swords, step into the dungeon, and put an end to evil once and for all!`,
    publisher: {
      title: 'Sambrela LLC',
      websiteUrl: 'https://www.runestonekeeper.com',
      email: 'support@runestonekeeper.com',
      privacyPolicyUrl: 'http://www.innoteg.com/',
    },
    rating: 4.1,
    votes: 155,
    myFavorite: false,
    platforms: [
      { icon: 'icon-android' },
      { icon: 'icon-macos' },
      { icon: 'icon-desktop' },
    ],
  },
  {
    // mini-block
    id: '2',
    title: 'Deer Hunting Target',
    images: [
      '/assets/images/game-2.png',
      '/assets/images/gallery/g-1.png',
      '/assets/images/gallery/g-2.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
      '/assets/images/gallery/g-3.png',
    ],
    thumb: '/assets/images/game-icon.png',
    categories: [mockCategories[0], mockCategories[0], mockCategories[0]],
    subtitle: '',
    description: `Runestone Keeper is a roguelike, turn based dungeon crawler featuring classic elements from many of your old game collections.

    Grab your leather pack, sharpen them dusty swords, step into the dungeon, and put an end to evil once and for all!`,
    publisher: {
      title: 'Sambrela LLC',
      websiteUrl: 'https://www.runestonekeeper.com',
      email: 'support@runestonekeeper.com',
      privacyPolicyUrl: 'http://www.innoteg.com/',
    },
    rating: 4.1,
    votes: 155,
    myFavorite: false,
    platforms: [
      { icon: 'icon-android' },
      { icon: 'icon-macos' },
      { icon: 'icon-desktop' },
    ],
  },
];

export const mockAsset: Asset = {
  id: '1',
  title: 'Steampunk GameAsset #234',
  image: { large: '/assets/images/asset.png' },
  forGame: mockGames[2],
  price: 2.21,
  oldPrice: 2.59,
  status: 'on_sale',
};

export const mockReviews: GameReview[] = [
  {
    id: '1',
    date: '2023-08-23',
    rating: 4,
    content:
      'Lorem ipsum dolor sit amet consectetur. Lectus mattis pellentesque pellentesque volutpat nec eu. Ullamcorper nisi egestas pellentesque feugiat. Pretium in dui suspendisse velit sem.',
    user: {
      id: '1',
      name: 'Username1',
      avatar: '/assets/images/me.svg',
    },
  },
  {
    id: '2',
    date: '2023-08-23',
    rating: 5,
    content:
      'Lorem ipsum dolor sit amet consectetur. Lectus mattis pellentesque pellentesque volutpat nec eu. Ullamcorper nisi egestas pellentesque feugiat. Pretium in dui suspendisse velit sem.',
    user: {
      id: '2',
      name: 'Username2',
      avatar: '/assets/images/me.svg',
    },
  },
  {
    id: '3',
    date: '2023-08-23',
    rating: 3,
    content:
      'Lorem ipsum dolor sit amet consectetur. Lectus mattis pellentesque pellentesque volutpat nec eu. Ullamcorper nisi egestas pellentesque feugiat. Pretium in dui suspendisse velit sem.',
    user: {
      id: '3',
      name: 'Username3',
      avatar: '/assets/images/me.svg',
    },
  },
  {
    id: '4',
    date: '2023-08-23',
    rating: 4,
    content:
      'Lorem ipsum dolor sit amet consectetur. Lectus mattis pellentesque pellentesque volutpat nec eu. Ullamcorper nisi egestas pellentesque feugiat. Pretium in dui suspendisse velit sem.',
    user: {
      id: '4',
      name: 'Username4',
      avatar: '/assets/images/me.svg',
    },
  },
  {
    id: '5',
    date: '2023-08-23',
    rating: 3,
    content:
      'Lorem ipsum dolor sit amet consectetur. Lectus mattis pellentesque pellentesque volutpat nec eu. Ullamcorper nisi egestas pellentesque feugiat. Pretium in dui suspendisse velit sem.',
    user: {
      id: '5',
      name: 'Username5',
      avatar: '/assets/images/me.svg',
    },
  },
];

export const mockStats: Statistics = {
  countRating1: 5,
  countRating2: 15,
  countRating3: 10,
  countRating4: 40,
  countRating5: 80,
  reviewsCount: 150,
  usersCount: 3534,
};
