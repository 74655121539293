/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Page {
  value: number;
  text: string;
  active: boolean;
}

const minNavItems = 6;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  curPage = 0;
  maxPage = 0;
  pages: Page[] = [];

  private totalItemsCount = 0;
  private maxNavItems = minNavItems;

  @Input() set page(val: number) {
    this.curPage = val;
    this.recalc();
  }

  @Input() pageSize = 12;

  @Input() set maxSize(val:number) {
    this.maxNavItems = val > minNavItems
      ? val
      : minNavItems;

    this.recalc();
  }

  @Input() set totalItems(val: number) {
    this.totalItemsCount = val;
    this.maxPage = Math.max(Math.ceil(val / this.pageSize), 1);
    this.recalc();
  }

  @Output() pageChanged = new EventEmitter<number>();

  prevPage() {
    if (this.curPage > 1) {
      this.curPage -= 1;
      this.pageChanged.emit(this.curPage);
    }
  }

  nextPage() {
    if (this.curPage < this.maxPage) {
      this.curPage += 1;
      this.pageChanged.emit(this.curPage);
    }
  }

  setCurPage(val: number) {
    this.curPage = val;
    this.pageChanged.emit(this.curPage);
    this.recalc();
  }

  private recalc() {
    const halfWay = Math.ceil(this.maxNavItems / 2);
    const isStart = this.curPage <= halfWay;
    const isEnd = this.maxPage - halfWay < this.curPage;
    const isMiddle = !isStart && !isEnd;
    const ellipsesNeeded = this.maxNavItems < this.maxPage;
    let i = 1;

    this.pages = [];

    while (i <= this.maxPage && i <= this.maxNavItems) {
      let text: string;
      const pageNumber = this.calculatePageNumber(i, halfWay);
      const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
      const closingEllipsesNeeded = (i === this.maxNavItems - 1 && (isMiddle || isStart));

      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        text = '...';
      } else {
        text = pageNumber.toString();
      }

      this.pages.push({
        text,
        value: pageNumber,
        active: pageNumber === this.curPage,
      });

      i += 1;
    }
  }

  private calculatePageNumber(i: number, halfWay: number) {
    if (i === this.maxNavItems) {
      return this.maxPage;
    } else if (i === 1) {
      return i;
    } else if (this.maxNavItems < this.maxPage) {
      if (this.maxPage - halfWay < this.curPage) {
        return this.maxPage - this.maxNavItems + i;
      } else if (halfWay < this.curPage) {
        return this.curPage - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }
}
