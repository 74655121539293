export const TEXT_FIELD_DEBOUNCE_TIME = 600;

export enum View {
  Icons, List,
}

export enum Sort {
  RecentlyAdded = 'recently-added',
  Rating = 'rating',
  ReleaseDate = 'release-date',
  Popularity = 'popularity',
  Relevance = 'relevance',
  PriceLow2High = 'price:asc',
  PriceHigh2Low = 'price:desc',
  NewestArrivals = 'newest-arrivals',
}

export enum GameGroups {
  Popular = 'popular',
  NewlyListed = 'newly-listed',
  CommunityChoice = 'community-choice',
  TopRated = 'top-rated',
}

export interface GamesFilter {
  categoryId?: string | null;
  group?: GameGroups | null;
  search?: string | null;
}

export interface CustomSelectOption {
  title: string;
  value: unknown;
}
