export interface Pageable<T = unknown> {
  items: T[];
  totalItems: number;
}

export enum DAppPlatforms {
  Windows = 'win',
  Android = 'android',
  Ios = 'ios',
  Osx = 'osx',
}

export enum SocialNetworks {
  Telegram = 'telegram',
  Discord = 'discord',
  Facebook = 'facebook',
  X = 'x',
  Instagram = 'instagram',
}

export enum DAppQueryTypes {
  Generic = '',
  Search = 'search',
  Popular = 'popular',
  NewlyListed = 'newlyListed',
  CommunityChoice = 'communityChoice',
  TopRated = 'topRated',
  TopCategory = 'topCategory',
}

export interface DAppQueryParams {
  queryType: DAppQueryTypes;
  query?: string; // must be not empty
  startAfter?: string;
  endBefore?: string;
  limit: number;
}

export interface DAppImage {
  description?: string;
  source:	string;
  large:	string;
  medium:	string;
  small:	string;
}

export interface DAppScreenshot {
  platformId: string;
  images: DAppImage[];
}

export interface DAppDeveloper {
  id: string;
  name: string;
  description: string;
  dappIds: string[];
  webSiteUrl: string;
  email: string;
  privacyPolicy: string;
  createdAt?: number;
  updatedAt?: number;
  createdBy?: string;
  updatedBy?: string;
}

export interface SocialUrl {
  networkId: string;
  url: string;
}

export interface Blockchain {
  networkId: string;
  url: string;
}

export interface DApp {
  id:	string;
  title:	string;
  subTitle:	string;
  description:	string;
  categories:	string[];
  developerId:	string;
  platforms:	string[];
  tags:	string[];
  screenshots: DAppScreenshot[];
  icon:	DAppImage;
  banner: DAppImage;
  socialUrls: SocialUrl[];
  blockchains: Blockchain[];
  rgnProjectId?: string;
  createdAt?: number;
  createdBy?: string;
  updatedAt?: number;
  updatedBy?: string;
  isListed?: boolean;
  listedAt?: number;
  listedBy?: string;
  approveRequestId: string;
  approvedAt? : number;
  approvedBy?: string;
}

export interface DAppCategory {
  id: string;
  name: string;
  description: string;
  image?: DAppImage;
  tags: string[];
  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
}

export interface Icon {
  name: string;
  iconClass: string;
  link?: string | string[];
}

export interface DAppHomePageData {
  upcomingEvents: {
    banner: DAppImage[];
    url: string;
  }[];
  queryResults:	{
    queryType: DAppQueryTypes;
    dappIds: string[];
    query?:	string;
  }[];
  dapps: DApp[];
  categories: DAppCategory[];
}

export interface Wallet {
  address: string;
  is_rgn_wallet: boolean;
}

export interface DAppAsset {
  id: string;
  image: DAppImage;
}

export interface InventoryItem {
  id: string;
  appId: string;
  createdAt: number;
  assets: string[];
}
